@use '../config/' as *;

.waf-map{
    &.waf-component{
        @extend %py-10;
        @extend %mb-0;
    }
    .title{
        @extend %title-40;
        @extend %mb-8;
    }
    .map{
        &-title{
            @extend %title-50;
            @extend %mb-6;
        }
        &-item{
            max-width: 72rem;
            @extend %mx-auto;
            + .map-item{
                @extend %mt-8;
                @extend %pt-8;
                @include border(1, neutral-20, 10, top);
            }       
            img{
                aspect-ratio: 4/3;
                max-height: 25rem;
                @extend %mt-8;
                @extend %border-radius-m;
            }
        }
        &-head{
            a {
                width: max-content;
                justify-content: space-between;
                @extend %btn-outline;
                @extend %gap-2;
                &:after {
                    font-weight: 400;
                    @include icon(arrow-down-tray, 19);
                }
            }
        }
    }
}

@include mq(col-tablet){
    .waf-map{
        &.waf-component{
            padding-block: var(--space-20);
        }
        .title{
            font-size: 4rem;
            margin-bottom: var(--space-20);
        }
        .map{
            &-title{
                margin-bottom: 0;
                width: 59%;
            }
            &-item{
                padding-inline: var(--space-12);
                + .map-item{
                    margin-top: var(--space-10);
                    padding-top: var(--space-10);
                }       
                img{
                    aspect-ratio: 3/2;
                    max-height: 81.3rem;
                    margin-top: var(--space-10);
                }
            }
            &-head{
               gap: var(--space-6);
               @include flex-config(flex, row, space-between, center);
            }
        }
    }
}